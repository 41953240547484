import React, { PureComponent, ReactNode } from "react";
import { Box } from '@mui/material';
import '../Statistics.css';
import { CartesianData } from "../../../Domain/Entity/CartesianData";
import { ApplicationContext } from "../../../../Configuration/Application.context";
import { EmployeeData } from "../../../Domain/Entity/EmployeeData";
import { SerieData } from "../../../Domain/Entity/SerieData";
import { i18n } from "../../../../Configuration/I18n";
import { StatisticsAreaChart } from "../Component/AreaChart";
import { StatisticsBadgesTable } from "./Components/StatisticsBadgesTable";
import { UsersStats } from "./Components/UsersStats";
import { StatsByRegion } from "./Components/StatsByRegion";
import { PeriodFilter } from "./Components/PeriodFilter";
import { AvatarStats } from "./Components/AvatarStats";
import { PageStats } from "./Components/PageStats";
import { Region } from "../../../../Agence/Domain/Entities/Region";

const moment = ApplicationContext.getInstance().momentJs()

interface Props {
    loginStats: CartesianData[] | null;
    sessionStats: CartesianData[] | null;
    avatarStats: CartesianData[] | null;
    getLoginStats: (startDate: string, endDate: string) => void;
    getSessionStats: (startDate: string, endDate: string) => void;
    getAvatarStats: () => void;
    employeeStats: EmployeeData | null
    getEmployeeStats: () => void
    setTopBarTitle: (title: string) => void
    badgesStats: SerieData[] | null
    getBadgeStats: () => void
    pageStats: CartesianData[] | null
    getPageStats: () => void
    regions: Region [] | null
}

export class StatisticsContainer extends PureComponent<Props> {

    componentDidMount() {
        this.props.getLoginStats('', '')
        this.props.getSessionStats('', '')
        this.props.getAvatarStats()
        this.props.getEmployeeStats()
        this.props.setTopBarTitle(i18n.statistics.title_general_stats)
        this.props.getBadgeStats()
        this.props.getPageStats()
    }

    render(): ReactNode {
        return (
            <Box>
                <UsersStats employeeStats={this.props.employeeStats}
                            usersStats={this.props.loginStats}
                            onFilter={value => this.onFilterLoginStats(value)}/>

                <StatsByRegion employeeStats={this.props.employeeStats} regions={this.props.regions}/>

                <StatisticsAreaChart title={i18n.statistics.sessions}
                                     stats={this.props.sessionStats}
                                     legend={i18n.statistics.sessions_number}>
                    <PeriodFilter onFilter={value => this.onFilterSessionStats(value)}/>
                </StatisticsAreaChart>

                <PageStats pageStats={this.props.pageStats}/>

                <StatisticsBadgesTable title={i18n.statistics.badges_stats_title}
                                       stats={this.props.badgesStats ? this.props.badgesStats : []}
                                       regions={this.props.regions}/>

                <AvatarStats avatarStats={this.props.avatarStats}
                             employeeStats={this.props.employeeStats}/>
            </Box>
        )
    }


    onFilterLoginStats(startDate: string) {
        if (startDate !== 'all') {
            const endDate = moment().format('YYYY-MM-DD')
            this.props.getLoginStats(startDate, endDate)
        } else
            this.props.getLoginStats('', '')
    }

    onFilterSessionStats(startDate: string) {
        if (startDate !== 'all') {
            const endDate = moment().format('YYYY-MM-DD')
            this.props.getSessionStats(startDate, endDate)
        }
        else
            this.props.getSessionStats('', '')
    }
}
