import React, { PureComponent, ReactNode } from 'react'
import { ChoiceType } from "../../../../../Common/Domain/Entiities/ChoiceType";
import { i18n } from "../../../../../Configuration/I18n";
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { ApplicationContext } from "../../../../../Configuration/Application.context";

const moment = ApplicationContext.getInstance().momentJs()

interface Props {
    onFilter: (value: string) => void
}

interface State {
    selected: string
}

export class PeriodFilter extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            selected: this.generateFilters()[0].value
        }
    }

    render(): ReactNode {
        return (
            <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                    <InputLabel id="status-select-label">{i18n.message.status}</InputLabel>

                    <Select id="status-select"
                            labelId="status-select-label"
                            value={this.state.selected}
                            label={i18n.statistics.period}
                            onChange={(event: SelectChangeEvent) => {
                                this.setState({ selected: event.target.value })
                                this.props.onFilter(event.target.value)
                            }}>
                        {this.generateFilters().map(item => <MenuItem key={item.value}
                                                               value={item.value}>{item.label}</MenuItem>)}

                    </Select>
                </FormControl>
            </Grid>
        )
    }

    generateFilters(): ChoiceType[] {
        return [{ label: i18n.statistics.all, value: 'all' },
            { label: i18n.statistics.last_week, value: moment().subtract(7, 'day').format('YYYY-MM-DD') },
            { label: i18n.statistics.last_month, value: moment().subtract(30, 'day').format('YYYY-MM-DD') },
            { label: i18n.statistics.last_6_month, value: moment().subtract(180, 'day').format('YYYY-MM-DD') },
            { label: i18n.statistics.last_year, value: moment().subtract(360, 'day').format('YYYY-MM-DD') },
        ]
    }
}