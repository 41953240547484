import React, { PureComponent, ReactNode } from "react";
import { Box } from '@mui/material';
import { AdventureData } from "../../../Domain/Entity/AdventureData";
import { Adventure } from "../../../../Adventure/Domain/Entity/Adventure";
import { i18n } from "../../../../Configuration/I18n";
import { SerieData } from "../../../Domain/Entity/SerieData";
import { EmployeeData } from "../../../Domain/Entity/EmployeeData";
import { AdventureSubscriptionStats } from "./Components/SubscriptionStats";
import { AdventureProgressStats } from "./Components/ProgressStats";
import { AdventureGoalAchievementStats } from "./Components/GoalAchievementStats";
import { AdventureScoreStats } from "./Components/ScoreStats";
import { AdventureMCQStats } from "./Components/MCQStats";
import { AdventureSelectInput } from "./Components/AdventureSelectInput";
import { StatisticsSpinner } from "../Component/Spinner";

interface Props {
    adventureList: Adventure[] | null;
    adventureStats: AdventureData | null;
    mcqStats: SerieData[] | null;
    loading: boolean;
    loadAdventureList: () => void;
    getAdventureStats: (adventureId: string) => void;
    getMcqStats: (adventureId: string) => void;
    adventureSubscriptionStats: SerieData[] | null
    getAdventureSubscriptionStats: (adventureId: string) => void;
    setTopBarTitle: (title: string) => void
    employeeStats: EmployeeData | null
    getEmployeeStats: () => void
}

interface State {
    selectedAdventure: string
}

export class AdventureStatisticsContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            selectedAdventure: this.props.adventureList && this.props.adventureList.length > 0 ? this.props.adventureList[0].id : ''
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.adventureList && this.props.adventureList.length > 0 && this.props.adventureList !== prevProps.adventureList)
            this.getAdventureStats(this.props.adventureList[0].id)
    }

    componentDidMount() {
        this.props.setTopBarTitle(i18n.statistics.adventure_title)
        if (!this.props.adventureList)
            this.props.loadAdventureList()
        if (!this.props.employeeStats)
            this.props.getEmployeeStats()
    }

    render(): ReactNode {
        if (this.props.adventureStats && this.props.adventureList)
            return (
                <Box>
                    <AdventureSelectInput value={this.state.selectedAdventure}
                                          adventures={this.props.adventureList}
                                          onChange={(adventureId: string) => this.getAdventureStats(adventureId)}/>

                    <AdventureSubscriptionStats adventureSubscriptionStats={this.props.adventureSubscriptionStats}
                                                employeeStats={this.props.employeeStats}/>

                    <AdventureProgressStats adventureStats={this.props.adventureStats}/>

                    <AdventureGoalAchievementStats adventureStats={this.props.adventureStats}/>

                    <AdventureScoreStats adventureStats={this.props.adventureStats}/>

                    <AdventureMCQStats mcqStats={this.props.mcqStats}/>
                </Box>
            )
        else
            return <StatisticsSpinner loading={this.props.loading}
                                      text={i18n.statistics.no_challenge_mission}/>
    }

    getAdventureStats(adventureId: string) {
        this.setState({
            selectedAdventure: adventureId
        }, () => {
            this.props.getAdventureStats(adventureId)
            this.props.getMcqStats(adventureId)
            this.props.getAdventureSubscriptionStats(adventureId)
        })
    }

}
