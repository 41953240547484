import { AdventureData } from "../../Domain/Entity/AdventureData";

export const GET_ADVENTURE_STATS = 'GET_ADVENTURE_STATS'
export const GET_ADVENTURE_STATS_FAILED = 'GET_ADVENTURE_STATS_FAILED'
export const GET_ADVENTURE_STATS_SUCCEEDED = 'GET_ADVENTURE_STATS_SUCCEEDED'

export interface GetAdventureStatsAction {
    type: typeof GET_ADVENTURE_STATS;
    payload: string;
}

interface GetAdventureStatsFailedAction {
    type: typeof GET_ADVENTURE_STATS_FAILED;
    payload: string;
}

interface GetAdventureStatsSucceededAction {
    type: typeof GET_ADVENTURE_STATS_SUCCEEDED;
    payload: AdventureData;
}

export type GetAdventureStatsActionTypes =
    GetAdventureStatsAction
    | GetAdventureStatsFailedAction
    | GetAdventureStatsSucceededAction
