import { connect } from 'react-redux';
import { Dispatch } from "redux";
import { AppState } from "../../../../Redux-configuration/AppState";
import { StatisticsContainer } from "./Statistics.container";
import { CartesianData } from "../../../Domain/Entity/CartesianData";
import { GetLoginStatsActionTypes } from "../../../Usecases/GetLoginStats/actionTypes";
import { getLoginStats } from "../../../Usecases/GetLoginStats/actions";
import { loginStatsSelector } from "../../../Usecases/GetLoginStats/selector";
import { GetSessionStatsActionTypes } from "../../../Usecases/GetSessionStats/actionTypes";
import { getSessionStats } from "../../../Usecases/GetSessionStats/actions";
import { sessionStatsSelector } from "../../../Usecases/GetSessionStats/selector";
import { GetAvatarStatsActionTypes } from "../../../Usecases/GetAvatarStats/actionTypes";
import { getAvatarStats } from "../../../Usecases/GetAvatarStats/actions";
import { avatarStatsSelector } from "../../../Usecases/GetAvatarStats/selector";
import { EmployeeData } from "../../../Domain/Entity/EmployeeData";
import { employeeStatsSelector } from "../../../Usecases/GetEmployeeStats/selector";
import { GetEmployeeStatsAction } from "../../../Usecases/GetEmployeeStats/actionTypes";
import { getEmployeeStats } from "../../../Usecases/GetEmployeeStats/actions";
import { SetTopBarTitleAction } from "../../../../Common/Usecases/SetConfigurationApp/actionTypes";
import { setTopBarTitle } from "../../../../Common/Usecases/SetConfigurationApp/actions";
import { SerieData } from "../../../Domain/Entity/SerieData";
import { badgesStatsSelector } from "../../../Usecases/GetBadgesStats/selectors";
import { GetBadgesStatsAction } from "../../../Usecases/GetBadgesStats/actionTypes";
import { getBadgesStats } from "../../../Usecases/GetBadgesStats/actions";
import { pageStatsSelector } from "../../../Usecases/GetPageStats/selectors";
import { GetPageStatsAction } from "../../../Usecases/GetPageStats/actionTypes";
import { getPageStats } from "../../../Usecases/GetPageStats/actions";
import { Region } from "../../../../Agence/Domain/Entities/Region";
import { regionsListSelector } from "../../../../Agence/Usecases/GetRegionList/selector";

interface StateToPropsType {
    loginStats: CartesianData[] | null;
    sessionStats: CartesianData[] | null;
    avatarStats: CartesianData[] | null;
    employeeStats: EmployeeData | null
    badgesStats: SerieData[] | null
    pageStats: CartesianData[] | null
    regions: Region[] | null
}

interface DispatchToPropsType {
    getLoginStats: (startDate: string, endDate: string) => void;
    getSessionStats: (startDate: string, endDate: string) => void;
    getAvatarStats: () => void;
    getEmployeeStats: () => void
    setTopBarTitle: (title: string) => void
    getBadgeStats: () => void
    getPageStats: () => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loginStats   : loginStatsSelector(state),
    sessionStats : sessionStatsSelector(state),
    avatarStats  : avatarStatsSelector(state),
    employeeStats: employeeStatsSelector(state),
    badgesStats  : badgesStatsSelector(state),
    pageStats    : pageStatsSelector(state),
    regions      : regionsListSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    getLoginStats   : (startDate: string, endDate: string): GetLoginStatsActionTypes => dispatch(getLoginStats(startDate, endDate)),
    getSessionStats : (startDate: string, endDate: string): GetSessionStatsActionTypes => dispatch(getSessionStats(startDate, endDate)),
    getAvatarStats  : (): GetAvatarStatsActionTypes => dispatch(getAvatarStats()),
    getEmployeeStats: (): GetEmployeeStatsAction => dispatch(getEmployeeStats()),
    setTopBarTitle  : (title: string): SetTopBarTitleAction => dispatch(setTopBarTitle(title)),
    getBadgeStats   : (): GetBadgesStatsAction => dispatch(getBadgesStats()),
    getPageStats    : (): GetPageStatsAction => dispatch(getPageStats())
})

export const StatisticsPage = connect(mapStateToProps, mapDispatchToProps)(StatisticsContainer)
