import React, { PureComponent, ReactNode } from 'react'
import { i18n } from "../../../../../Configuration/I18n";
import { Alert, Box, TextField } from "@mui/material";
import { FormBlock } from "../../../../../Common/Adapters/Primaries/Components/Form/FormBlock";
import { TextEditor } from "../../../../../Common/Adapters/Primaries/Components/Form/TextEditor";
import { PictureInput } from "../../../../../Common/Adapters/Primaries/Components/Form/PictureInput";
import { DateTimePicker } from "../../../../../Common/Adapters/Primaries/Components/DateTimePicker";
import { AdventureFormType } from "../type/AdventureFormType";
import { AdventureFormValidation } from "../AdventureFormValidation";

interface Props {
    adventure: AdventureFormType
    onChange: (key: keyof AdventureFormType, value: string | number | File) => void
    error: boolean
}

interface State {
    errorTitre: boolean
    errorReward: boolean
    errorDetails: boolean
    errorDate: boolean
    errorPicture: boolean
}

export class AdventureDetailsBlock extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            errorTitre  : false,
            errorReward : false,
            errorDetails: false,
            errorDate   : false,
            errorPicture: false
        }
    }

    componentDidUpdate() {
        if (this.props.error === true) {
            this.setState({
                errorTitre  : AdventureFormValidation.isNotValidName(this.props.adventure.name),
                errorReward : AdventureFormValidation.isNotValidReward(this.props.adventure.reward),
                errorDetails: AdventureFormValidation.isNotValidDetails(this.props.adventure.details),
                errorDate   : AdventureFormValidation.isNotValidDate(this.props.adventure.startDate, this.props.adventure.endDate),
                errorPicture: AdventureFormValidation.isNotValidPicture(this.props.adventure.picture, this.props.adventure.pictureName)
            })
        }
    }

    render(): ReactNode {
        return (
            <Box>
                <FormBlock title={i18n.adventure.adventure}>
                    <TextField fullWidth required
                               sx={{ mb: 2 }}
                               label={i18n.adventure.title}
                               value={this.props.adventure.name}
                               error={this.state.errorTitre}
                               onChange={(event) => this.props.onChange('name', event.target.value)}/>

                    <TextField fullWidth required
                               sx={{ mb: 2 }}
                               label={i18n.adventure.reward}
                               error={this.state.errorReward}
                               value={this.props.adventure.reward}
                               onChange={(event) => this.props.onChange('reward', Number(event.target.value))}/>

                    <TextEditor label={i18n.adventure.details}
                                value={this.props.adventure.details}
                                error={this.state.errorDetails}
                                required={true}
                                onChange={(details) => this.props.onChange('details', details)}/>

                </FormBlock>

                <FormBlock title={i18n.adventure.picture_required}
                           description={i18n.adventure.picture_description}>
                    <PictureInput value={this.props.adventure.pictureName}
                                  id={'adventure-picture'}
                                  description={i18n.adventure.picture_details(640, 212)}
                                  width={640}
                                  height={212}
                                  error={this.state.errorPicture}
                                  onChange={(picture) => {
                                      this.props.onChange('picture', picture)
                                      this.props.onChange('pictureName', picture.name)
                                  }}/>
                </FormBlock>

                <FormBlock title={i18n.adventure.date}>
                    <DateTimePicker startDate={this.props.adventure.startDate}
                                    required={true}
                                    sx={{ mb: 2 }}
                                    endDate={this.props.adventure.endDate}
                                    error={this.state.errorDate}
                                    onChange={(startDate: string, endDate: string) => {
                                        if (startDate !== this.props.adventure.startDate)
                                            this.props.onChange('startDate', startDate)
                                        if (endDate !== this.props.adventure.endDate)
                                            this.props.onChange('endDate', endDate)
                                    }}/>
                    {this.state.errorDate ? <Alert severity="error">{i18n.adventure.error_adventure_dates}</Alert> : null}

                </FormBlock>
            </Box>
        )
    }
}
