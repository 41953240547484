import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GET_ADVENTURE_STATS, GetAdventureStatsAction } from "./actionTypes";
import { GetAdventureStatsState } from "../../Config/State";
import { StatisticsService } from "../../Domain/Gateway/Statistics.service";
import { AdventureData } from "../../Domain/Entity/AdventureData";
import { getAdventureStatsFailed, getAdventureStatsSucceeded } from "./actions";

export const getAdventureStatsEpic: Epic = (action$: ActionsObservable<GetAdventureStatsAction>,
                                             store: StateObservable<GetAdventureStatsState>,
                                             { statisticsService }: { statisticsService: StatisticsService }) =>
    action$.pipe(
        ofType(GET_ADVENTURE_STATS),
        switchMap(action => statisticsService.getAdventureStats(action.payload)
            .pipe(
                map((stats: AdventureData) => getAdventureStatsSucceeded(stats)),
                catchError((error: string) => of(getAdventureStatsFailed(error)))
            )
        )
    )
