import React, { PureComponent, ReactNode } from 'react'
import { Adventure } from "../../../Domain/Entity/Adventure";
import { MCQQuestion } from "../../../Domain/Entity/MCQQuestion";
import { MCQ } from "../../../Domain/Entity/MCQ";
import { Alert, Box, Button, CircularProgress } from "@mui/material";
import { match, RouteComponentProps } from "react-router";
import { i18n } from "../../../../Configuration/I18n";
import { AdventureDetailsBlock } from "../AddAdventure/components/AdventureDetails.block";
import { PageTitle } from "../../../../Common/Adapters/Primaries/Components/PageTitle";
import { AdventureFormType } from "../AddAdventure/type/AdventureFormType";
import { ApplicationContext } from "../../../../Configuration/Application.context";
import { MCQQuestionsUpdateBlock } from "./components/MCQQuestionsUpdateBlock";
import { AdventureFormValidation } from "../AddAdventure/AdventureFormValidation";
import { AdventureBuilder } from "../../../Domain/Builder/Adventure.builder";

interface Props extends RouteComponentProps {
    getAdventureList: () => void;
    loadAdventureMCQ: (adventureId: string) => void
    updateAdventure: (adventure: Adventure) => void
    updateQuestion: (question: MCQQuestion, mcqId: string) => void
    createQuestion: (question: MCQQuestion, mcqId: string) => void
    adventures: Adventure[] | null;
    loading: boolean;
    MCQ: MCQ | null
    error: string | undefined
    match: match<{ id: string }>
    setTopBarTitle: (title: string) => void
}

interface State extends AdventureFormType {
    error: boolean
    errorMessage: string
}

const moment = ApplicationContext.getInstance().momentJs()

export class UpdateAdventureContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            name        : '',
            details     : '',
            picture     : undefined,
            pictureName : undefined,
            startDate   : '',
            endDate     : '',
            enabled     : true,
            reward      : 0,
            MCQSettings : {
                title    : '',
                picture  : undefined,
                reward   : 0,
                questions: []
            },
            error       : false,
            errorMessage: ''
        }
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.adventures && state.name === '') {
            const adventure: Adventure | undefined = props.adventures.find((item: Adventure) => item.id === props.match.params.id)
            if (adventure)
                return {
                    ...state,
                    name       : adventure.name,
                    details    : adventure.details,
                    picture    : undefined,
                    pictureName: adventure.pictureName,
                    startDate  : moment(adventure.startDate).format('YYYY-MM-DDTHH:mm'),
                    endDate    : moment(adventure.endDate).format('YYYY-MM-DDTHH:mm'),
                    enabled    : adventure.enabled,
                    reward     : adventure.reward
                }
        }
        if (props.MCQ !== null && state.MCQSettings.title === '')
            return {
                ...state, MCQSettings: {
                    title    : props.MCQ.title,
                    picture  : undefined,
                    reward   : props.MCQ.reward,
                    questions: props.MCQ.questions
                }
            }
        return null
    }

    componentDidMount() {
        if (!this.props.adventures)
            this.props.getAdventureList()
        this.props.loadAdventureMCQ(this.props.match.params.id)
        this.props.setTopBarTitle(i18n.adventure.adventure_title)
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.error !== undefined)
            this.setState({ errorMessage: this.props.error })
        if (this.props.loading === false && prevProps.loading === true && this.props.error === undefined)
            this.props.history.push('/adventure-list')

    }

    render(): ReactNode {
        return (
            <Box>
                <PageTitle title={i18n.adventure.update_adventure(this.state.name)}/>
                <Box component="form" noValidate autoComplete="off">
                    <AdventureDetailsBlock adventure={this.state}
                                           error={this.state.error}
                                           onChange={(key, value): void => {
                                               this.setState({
                                                   ...this.state, [key]: value
                                               })
                                           }}/>
                    <MCQQuestionsUpdateBlock error={this.state.error}
                                             questions={this.state.MCQSettings.questions}
                                             onCreate={question => this.addQuestion(question)}
                                             onUpdate={question => this.updateQuestion(question)}
                    />

                    {this.state.errorMessage !== '' ?
                        <Alert severity="error">{this.state.errorMessage}</Alert> : null}
                    {this.props.loading ?
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress/></Box> : null}
                    <Button variant="contained" onClick={() => this.updateAdventure()}>
                        {i18n.adventure.save}
                    </Button>
                </Box>
            </Box>
        )
    }

    addQuestion(question: MCQQuestion) {
        this.setState({ errorMessage: '' })
        const questions: MCQQuestion[] = [...this.state.MCQSettings.questions]
        questions.push(question)
        this.setState({
            MCQSettings: {
                ...this.state.MCQSettings, questions
            }
        })
        if (this.props.MCQ)
            this.props.createQuestion(question, this.props.MCQ.id)
    }

    updateQuestion(question: MCQQuestion) {
        this.setState({ errorMessage: '' })
        const questions: MCQQuestion[] = [...this.state.MCQSettings.questions]
        const indexQuestion: number = questions.findIndex(item => item.id === question.id)
        questions.splice(indexQuestion, 1, question)
        this.setState({
            MCQSettings: {
                ...this.state.MCQSettings, questions
            }
        })
        if (this.props.MCQ)
            this.props.updateQuestion(question, this.props.MCQ.id)

    }

    updateAdventure() {
        this.setState({ error: AdventureFormValidation.validate(this.state), errorMessage: '' }, () => {
            if (!this.state.error) {
                const adventure = new AdventureBuilder()
                    .withId(this.props.match.params.id)
                    .withName(this.state.name)
                    .withDetails(this.state.details)
                    .withStartDate(moment(this.state.startDate, 'YYYY-MM-DDTHH:mm').format())
                    .withEndDate(moment(this.state.endDate, 'YYYY-MM-DDTHH:mm').format())
                    .withReward(this.state.reward)
                    .withEnabled(this.state.enabled)
                if (this.state.picture)
                    adventure.withPicture(this.state.picture)

                this.props.updateAdventure(adventure.build())
            }
        })
    }
}