import React, { PureComponent, ReactNode } from 'react'
import { i18n } from "../../../../../Configuration/I18n";
import { Grid } from "@mui/material";
import { StatisticsPieChart } from "../../Component/PieChart";
import { AdventureData } from "../../../../Domain/Entity/AdventureData";
import { Theme } from "../../../../../Common/Config/Theme";

interface Props {
    adventureStats: AdventureData;
}

export class AdventureGoalAchievementStats extends PureComponent<Props> {
    render(): ReactNode {
        const colors = [Theme.darkGreen, Theme.softGreen, Theme.lightOrange, Theme.red]
        return (
            <Grid container spacing={3} mb={3}>
                <StatisticsPieChart size={6}
                                    colors={colors}
                                    title={i18n.statistics.tci_goal_achieved}
                                    info={i18n.statistics.tci_goal_achieved}
                                    stats={this.props.adventureStats.players}/>

                <StatisticsPieChart size={6}
                                    colors={colors}
                                    title={i18n.statistics.agency_goal_achieved}
                                    info={i18n.statistics.agency_goal_achieved}
                                    stats={this.props.adventureStats.agencies}/>
            </Grid>
        )
    }
}
