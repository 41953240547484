import React, { PureComponent, ReactNode } from 'react'
import { i18n } from "../../../../../Configuration/I18n";
import { CartesianData } from "../../../../Domain/Entity/CartesianData";
import { CartesianDataBuilder } from "../../../../Domain/Builder/CartesianData.builder";
import { Grid } from "@mui/material";
import { StatisticsPieChart } from "../../Component/PieChart";
import { EmployeeData } from "../../../../Domain/Entity/EmployeeData";
import { PieChartColors } from "../../../../Config/Theme";
import { Theme } from "../../../../../Common/Config/Theme";

interface Props {
    avatarStats: CartesianData[] | null;
    employeeStats: EmployeeData | null
}

export class AvatarStats extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <Grid container spacing={3}>
                <StatisticsPieChart title={i18n.statistics.nb_avatar}
                                    info={i18n.statistics.avatar_description}
                                    stats={this.getAllAvatarsStats(this.props.avatarStats)}
                                    colors={[Theme.darkGreen, Theme.red]}
                                    size={6}/>

                <StatisticsPieChart title={i18n.statistics.type_avatar}
                                    info={i18n.statistics.avatar_type_description}
                                    stats={this.getOrderedValues()}
                                    colors={PieChartColors}
                                    size={6}/>
            </Grid>
        )
    }

    getAllAvatarsStats(avatarStats: CartesianData[] | null): CartesianData[] {
        const usersWithAvatar = avatarStats ? avatarStats.reduce((partialSum, item) => partialSum + item.value, 0) : 0
        const usersWithoutAvatar = this.props.employeeStats ? this.props.employeeStats.getUsersCount() - usersWithAvatar : 0
        return [
            new CartesianDataBuilder()
                .withLabel(i18n.statistics.with_avatar)
                .withValue(usersWithAvatar)
                .build(),
            new CartesianDataBuilder()
                .withLabel(i18n.statistics.without_avatar)
                .withValue(usersWithoutAvatar)
                .build()
        ]
    }

    getOrderedValues() {
        if (this.props.avatarStats)
            return this.props.avatarStats.sort((a, b) => b.value - a.value)
        return []
    }
}
