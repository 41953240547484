import { CartesianData } from "./CartesianData";

export class EmployeeData {
    constructor(
        protected _employees: CartesianData[],
        protected _users: CartesianData[]
    ) {
    }

    getEmployeeCount(): number {
        return this._employees.reduce((partialSum, item) => partialSum + item.value, 0)
    }

    getUsersCount(): number {
        return this._users.reduce((partialSum, item) => partialSum + item.value, 0)
    }


    get users(): CartesianData[] {
        return this._users;
    }

    get employees(): CartesianData[] {
        return this._employees;
    }
}