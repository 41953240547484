import { GetAppStatsState } from "../../Config/State";
import {
    GET_SESSION_STATS,
    GET_SESSION_STATS_FAILED,
    GET_SESSION_STATS_SUCCEEDED,
    GetSessionStatsActionTypes
} from './actionTypes';

const initialState: GetAppStatsState = {
    loading: false,
    stats  : null,
    error  : undefined
}

export const getSessionStatsReducer = (state = initialState, action: GetSessionStatsActionTypes): GetAppStatsState => {
    switch (action.type) {
        case GET_SESSION_STATS:
            return {
                loading: true,
                stats  : null,
                error  : undefined
            }
        case GET_SESSION_STATS_SUCCEEDED:
            return {
                loading: false,
                stats  : action.payload,
                error  : undefined
            }
        case GET_SESSION_STATS_FAILED:
            return {
                loading: false,
                stats  : null,
                error  : action.payload
            }
        default:
            return state
    }
}
