export const StatisticsI18n = {
    title_chart_responses      : 'Distribution des réponses du SpeedQuiz',
    speed_quiz                 : 'Speed Quiz',
    no_speed_quiz              : 'No Speed Quiz',
    questions                  : 'Questions',
    correct_answers            : 'Bonnes réponses',
    bad_answers                : 'Mauvaises réponses',
    unanswered                 : 'Non répondus',
    empty_speed_quiz_data      : 'Pas de statistique pour ce SpeedQuiz',
    ask4password               : 'Demande de mot de passe',
    sessions                   : 'Sessions',
    nb_avatar                  : 'Nombre d\'avatar',
    type_avatar                : 'Types d\'avatar',
    login_succeeded            : 'Connexion réussie',
    ca                         : 'CA',
    goal                       : 'Objectif',
    start                      : 'Début',
    end                        : 'Fin',
    with_avatar                : 'Avec Avatar',
    without_avatar             : 'Sans Avatar',
    correct_answers_data       : 'Distribution des bonnes et mauvaises réponses',
    turnover                   : 'Chiffres d\'affaires',
    activated_client           : 'Clients mouvementés',
    more_than_100              : '100% et plus',
    between_80_and_100         : 'Entre 80% et 100%',
    between_50_and_80          : 'Entre 50% et 80%',
    less_thEn_50               : 'Moins de 50%',
    evolution_speedQuiz_title  : 'Répartition des réponses du SpeedQuiz par jour',
    adventure                  : 'Mission Challenge',
    tci_goal_achieved          : 'Atteintes d\'objectifs des itinérants',
    agency_goal_achieved       : 'Atteintes d\'objectifs des agences',
    tci_scores                 : 'Répartition du chiffre d\'affaire des itinérants',
    agency_scores              : 'Répartition du chiffre d\'affaire des agences',
    date                       : 'Date',
    evolution_response_legend  : 'Nombre de réponses',
    sessions_number            : 'Nombre de sessions',
    session_description        : 'Nombre des sessions ouvertes au court du temps',
    nb_login_succeeded         : 'Nombre de connexion réussie',
    nb_ask_password            : 'Nombre de demande de mot de passe',
    message                    : 'Message',
    opened_messages            : 'Messages lus',
    opened_message_rate        : 'Taux d\'ouverture des messages',
    description_view_message   : 'Le nombre total des personnes qui ont vu le message',
    evolution_message          : 'Évolution du nombre d\'ouvertures de message',
    adventure_subscription     : 'Évolution du nombre de participants à l’aventure',
    employees                  : 'Employés',
    users                      : 'Utilisateurs',
    subscription_rate          : 'Taux d\'adhésion à l\'application ',
    subscription_rate_by_region: 'Taux d\'adhésion par région',
    employees_region           : 'Répartition des utilisateurs par région',
    all                        : 'Tout',
    last_week                  : 'Dernière semaine',
    last_month                 : 'Dernier mois',
    last_6_month               : 'Dernier 6 mois',
    last_year                  : 'Dernière année',
    period                     : 'Période',
    cumulative_of_new_users    : 'Cumule des nouveaux utilisateurs',
    players_evolution          : 'Évolution du nombre de participants au Speed Quiz',
    quiz_players_number        : 'Nombre de participants',
    users_number               : 'Nombre d\'utilisateurs',
    employee_number            : 'Nombre d\'employés',
    title_general_stats        : 'Statistiques / Application',
    speedQuiz_title            : 'Statistiques / Mission Speed Quiz',
    message_title              : 'Statistiques / Messagerie',
    adventure_title            : 'Statistiques / Mission Challenge',
    badges_stats_title         : 'Répartition des badges',
    badges                     : 'Badges',
    percent                    : 'Pourcentage',
    avatar_description         : 'Nombre d\'utilisateurs ayant choisi un avatar comme photo de profil',
    avatar_type_description    : 'Les différents avatars choisis par les utilisateurs comme photo de profil',
    subscription_adventure_rate: 'Taux de participation à l’aventure',
    subscription               : 'Participants',
    turnover_goal              : 'Objectif de CA',
    activated_client_goal      : 'Objectif de CM',
    turnover_rate              : 'Pourcentage d\'atteinte des objectifs de CA ',
    activated_client_rate      : 'Pourcentage d\'atteinte des objectifs de CM',
    turnover_value             : 'CA atteint',
    activated_client_value     : 'CM atteint',
    news_subscription          : 'Nombre d\'inscriptions',
    participants               : 'Nombre de participants',
    no_data                    : 'Pas de données',
    stats_page_title           : 'Répartition du trafic dans l\'application',
    visit_number               : 'Nombre de visites',
    players                    : 'Joueurs',
    players_rate               : 'Taux de participation au SpeedQuiz',
    dashboard_page             : 'Tableau de bord',
    profile_page               : 'Profil utilisateur',
    score_page                 : 'Scores',
    message_page               : 'Messagerie',
    adventure_page             : 'Mission Challenge',
    speedQuiz_page             : 'Mission Speed Quiz',
    team_page                  : 'Profil collègue',
    open_number                : 'Nombre d\'ouverture',
    players_number             : 'Nombre de joueurs',
    players_speedQuiz_title    : 'Repartition de joueurs de SpeedQuiz',
    questions_mcq              : 'Questions du QCM',
    quantity                   : 'Quantité',
    no_speed_quiz_mission      : 'Pas de mission Speed Quiz',
    no_challenge_mission       : 'Pas de mission Challenge',
    no_messages                : 'Pas de messages'
}
