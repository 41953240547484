import { AdventureFormType, MCQQuestionFormType } from "./type/AdventureFormType";
import { ApplicationContext } from "../../../../Configuration/Application.context";

const moment = ApplicationContext.getInstance().momentJs()

export class AdventureFormValidation {
    static validate(state: AdventureFormType): boolean {
        return this.isNotValidName(state.name) || this.isNotValidDetails(state.details) ||
            this.isNotValidReward(state.reward) || this.isNotValidDate(state.startDate, state.endDate) ||
            this.isNotValidPicture(state.picture, state.pictureName) || this.isNotValidName(state.MCQSettings.title) ||
            this.isNotValidReward(state.MCQSettings.reward) ||
            this.isNotValidQuestions(state.MCQSettings.questions)
    }

    static isNotValidName(name: string): boolean {
        return name.length < 2
    }

    static isNotValidDetails(details: string): boolean {
        return details.length < 15
    }

    static isNotValidReward(reward: number): boolean {
        return reward < 1
    }

    static isNotValidDate(startDate: string, endDate: string): boolean {
        if (startDate.length > 0 && endDate.length > 0) {
            const _startDate = moment(startDate, 'YYYY-MM-DDTHH:mm')
            const _endDate = moment(endDate, 'YYYY-MM-DDTHH:mm')
            const duration = moment.duration(_endDate.diff(_startDate));
            const hours = duration.asHours()
            return _startDate.diff(moment()) < 0 || hours < 3
        }
        else
            return true
    }

    static isNotValidPicture(picture: File | undefined, pictureName: string | undefined): boolean {
        return picture === undefined && pictureName === undefined
    }

    static isNotValidQuestions(questions: MCQQuestionFormType[]): boolean {
        return questions.length < 10
    }
}