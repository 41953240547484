import React, { PureComponent, ReactNode } from 'react'
import { i18n } from "../../../../../Configuration/I18n";
import { StatisticsMultiBarChart } from "../../Component/MultiBarChart";
import { Grid } from "@mui/material";
import { AdventureData } from "../../../../Domain/Entity/AdventureData";

interface Props {
    adventureStats: AdventureData
}

export class AdventureScoreStats extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <Grid container spacing={3} mb={3}>
                <StatisticsMultiBarChart title={i18n.statistics.tci_scores} dataType={'CURRENCY'}
                                         stats={this.props.adventureStats.playersByRegion}/>

                <StatisticsMultiBarChart title={i18n.statistics.agency_scores} dataType={'CURRENCY'}
                                         stats={this.props.adventureStats.agenciesByRegion}/>
            </Grid>
        )
    }
}