import {
    GET_SESSION_STATS,
    GET_SESSION_STATS_FAILED,
    GET_SESSION_STATS_SUCCEEDED,
    GetSessionStatsActionTypes
} from './actionTypes';
import { CartesianData } from "../../Domain/Entity/CartesianData";

export const getSessionStats = (startDate: string, endDate: string): GetSessionStatsActionTypes => ({
    type   : GET_SESSION_STATS,
    payload: {
        startDate,
        endDate
    }
})

export const getSessionStatsFailed = (error: string): GetSessionStatsActionTypes => ({
    type   : GET_SESSION_STATS_FAILED,
    payload: error
})

export const getSessionStatsSucceeded = (stats: CartesianData[]): GetSessionStatsActionTypes => ({
    type   : GET_SESSION_STATS_SUCCEEDED,
    payload: stats
})
