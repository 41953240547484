import React, { PureComponent, ReactNode } from "react";
import { Box, Typography } from '@mui/material';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { Theme } from "../../../../Common/Config/Theme";

interface Props {
    title: string;
    numerator: number | undefined;
    denominator: number | undefined;
}

interface State {
    activeIndex: number;
}

export class PercentageCard extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            activeIndex: 0
        }
    }

    render(): ReactNode {
        const percent = this.props.numerator && this.props.denominator && this.props.denominator != 0  ? this.calculatePercentage() : "..."
        return (
            <Box className={'statistics-grid card card-chart'}>
                <ResponsiveContainer width={100} height={100}>
                    <PieChart width={100} height={100}>
                        <Pie activeIndex={this.state.activeIndex}
                             data={this.generateData()}
                             cx="50%"
                             cy="50%"
                             innerRadius={25}
                             outerRadius={40}
                             fill="#8884d8"
                             dataKey="value"
                             onMouseEnter={(children: ReactNode, index: number) => this.onPieEnter(children, index)}>

                            <Cell key={'cell-1'} fill={Theme.darkGreen}/>
                            <Cell key={'cell-2'} fill={Theme.red}/>

                        </Pie>
                    </PieChart>
                </ResponsiveContainer>

                <Box sx={{ ml: 2 }}>
                    <Typography>{percent}</Typography>

                    <Typography component={'span'}>
                        {this.props.title}
                    </Typography>
                </Box>
            </Box>
        )
    }

    private calculatePercentage(): string {

        if (this.props.numerator && this.props.denominator) {
            const options = { style: 'percent', maximumFractionDigits: 2 };
            const numberFormat = new Intl.NumberFormat('fr-FR', options);
            return numberFormat.format(this.props.numerator / this.props.denominator).replace(/\s/g, '  ');
        }
        else
            return ''
    }

    private generateData(): Array<{ value: number }> {
        if (this.props.numerator && this.props.denominator) {
            const division = this.props.numerator / this.props.denominator
            return division < 1 ?
                [
                    { value: this.props.numerator },
                    { value: this.props.denominator - this.props.numerator },
                ]
                : [
                    { value: 1 }
                ]
        }
        return [];
    }

    private onPieEnter = (e: ReactNode, index: number) => {
        this.setState({
            activeIndex: index
        })
    }
}
