import React, { PureComponent, ReactNode } from 'react'
import { SerieData } from "../../../Domain/Entity/SerieData";
import { Box } from "@mui/material";
import { CartesianData } from "../../../Domain/Entity/CartesianData";
import { i18n } from "../../../../Configuration/I18n";
import { SpeedQuizHeader } from "../../../../SpeedQuiz/Domain/Entities/SpeedQuizHeader";
import { EmployeeData } from "../../../Domain/Entity/EmployeeData";
import { SpeedQuizSelectInput } from "./Components/SpeedQuizSelectInput";
import { SpeedQuizPlayerStats } from "./Components/PlayerStats";
import { SpeedQuizResponseStats } from "./Components/ResponseStats";
import { SpeedQuizTable } from "./Components/SpeedQuizTable";
import { StatisticsSpinner } from "../Component/Spinner";

interface Props {
    allSpeedQuiz: SpeedQuizHeader[] | null;
    speedQuizStats: SerieData[] | null;
    loading: boolean;
    getAllSpeedQuiz: () => void;
    getSpeedQuizQuestionStats: (speedQuizId: string) => void;
    speedQuizResponseStats: CartesianData[] | null;
    getSpeedQuizResponseStats: (speedQuizId: string) => void;
    setTopBarTitle: (title: string) => void
    employeeStats: EmployeeData | null
    getEmployeeStats: () => void
    playerStats: CartesianData[] | null
    getSpeedQuizPlayerStats: (speedQuizId: string) => void
    getSpeedQuizPlayerCount: (speedQuizId: string) => void
    speedQuizPlayerCount: number | undefined
}

interface State {
    speedQuizSelected: string
}

export class SpeedQuizStatisticsContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            speedQuizSelected: this.props.allSpeedQuiz && this.props.allSpeedQuiz.length > 0 ? this.props.allSpeedQuiz[0].id : ''
        }
    }

    componentDidMount() {
        this.props.setTopBarTitle(i18n.statistics.speedQuiz_title)

        if (!this.props.allSpeedQuiz)
            this.props.getAllSpeedQuiz()

        if (!this.props.employeeStats)
            this.props.getEmployeeStats()
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.allSpeedQuiz && this.props.allSpeedQuiz.length > 0 && prevProps.allSpeedQuiz !== this.props.allSpeedQuiz) {
            this.getSpeedQuizStats(this.props.allSpeedQuiz[0].id)
        }
    }

    render(): ReactNode {
        if (this.props.allSpeedQuiz && this.props.allSpeedQuiz.length > 0)
            return (
                <Box>
                    <SpeedQuizSelectInput value={this.state.speedQuizSelected}
                                          onChange={(value: string) => this.getSpeedQuizStats(value)}
                                          speedQuiz={this.props.allSpeedQuiz}/>

                    <SpeedQuizPlayerStats usersCount={this.props.employeeStats? this.props.employeeStats.getUsersCount() : 0}
                                          speedQuizPlayerCount={this.props.speedQuizPlayerCount}
                                          playerStats={this.props.playerStats} />

                    <SpeedQuizResponseStats speedQuizResponseStats={this.props.speedQuizResponseStats}
                                            speedQuizStats={this.props.speedQuizStats}/>

                    <SpeedQuizTable speedQuizStats={this.props.speedQuizStats ? this.props.speedQuizStats : []}/>
                </Box>
            )
        else
            return <StatisticsSpinner loading={this.props.loading}
                                      text={i18n.statistics.no_speed_quiz_mission}/>
    }

    getSpeedQuizStats(speedQuizId: string) {
        this.setState({ speedQuizSelected: speedQuizId })
        this.props.getSpeedQuizQuestionStats(speedQuizId)
        this.props.getSpeedQuizResponseStats(speedQuizId)
        this.props.getSpeedQuizPlayerStats(speedQuizId)
        this.props.getSpeedQuizPlayerCount(speedQuizId)
    }

}
