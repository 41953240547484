import React, { PureComponent, ReactNode } from 'react'
import { Message } from "../../../../Message/Domain/Entity/Message";
import { CartesianData } from "../../../Domain/Entity/CartesianData";
import { i18n } from "../../../../Configuration/I18n";
import { Box, Grid } from "@mui/material";
import { StatisticsCard } from "../Component/Card";
import { EmployeeData } from "../../../Domain/Entity/EmployeeData";
import { PercentageCard } from "../Component/PercentageCard";
import { AreaChartWithLimit } from "../Component/AreaChartWithLimit";
import { MessageSelectInput } from "./Components/MessageSelectInput";
import { StatisticsSpinner } from "../Component/Spinner";

interface Props {
    loading: boolean;
    messages: Message[] | null
    messageStats: CartesianData[] | null
    loadMessages: () => void
    getMessageStats: (messageId: string) => void
    setTopBarTitle: (title: string) => void
    employeeStats: EmployeeData | null;
    getEmployeeStats: () => void;
}

interface State {
    selectedMessage: string
}

export class MessageStatisticsContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            selectedMessage: this.setSelectedMessage()
        }
    }

    componentDidMount() {
        this.props.setTopBarTitle(i18n.statistics.message_title)
        if (!this.props.messages)
            this.props.loadMessages()
        if (!this.props.employeeStats)
            this.props.getEmployeeStats()
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.messages && this.props.messages.length > 0 && this.props.messages !== prevProps.messages) {
            this.setState({
                selectedMessage: this.setSelectedMessage()
            })
            this.props.getMessageStats(this.props.messages[0].id)
        }
    }

    render(): ReactNode {
        if (this.props.messages && this.props.messages.length > 0)
            return (
                <Box>
                    <MessageSelectInput value={this.state.selectedMessage}
                                        messages={this.props.messages}
                                        onChange={(value: string) => this.getMessageStats(value)}/>

                    <Grid container spacing={3} mb={3}>
                        <Grid item xs={12} lg={4}>
                            <PercentageCard title={i18n.statistics.opened_message_rate}
                                            numerator={this.renderOpenedMessagesNumber()}
                                            denominator={this.props.employeeStats?.getUsersCount()}
                            />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <StatisticsCard title={i18n.statistics.opened_messages}
                                            value={this.renderOpenedMessagesNumber().toString()}/>
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <StatisticsCard title={i18n.statistics.users}
                                            value={this.renderUsersNumber()}/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} mb={3}>
                        <AreaChartWithLimit title={i18n.statistics.evolution_message}
                                            stats={this.props.messageStats}
                                            limitLabel={i18n.statistics.users_number}
                                            valueLabel={i18n.statistics.open_number}
                                            showLimit={true}
                                            limit={this.props.employeeStats ? this.props.employeeStats.getUsersCount() : 0}/>
                    </Grid>
                </Box>
            )
        else
            return <StatisticsSpinner loading={this.props.loading}
                                      text={i18n.statistics.no_messages}/>
    }

    setSelectedMessage(): string {
        return this.props.messages && this.props.messages.length > 0 ? this.props.messages[0].id : ''
    }

    getMessageStats(messageId: string) {
        this.setState({
            selectedMessage: messageId
        })
        this.props.getMessageStats(messageId)
    }

    renderOpenedMessagesNumber(): number {
        if (this.props.messageStats)
            return this.props.messageStats[this.props.messageStats.length -1].value
        else
            return 0
    }

    renderUsersNumber(): string {
        if (this.props.employeeStats)
            return this.props.employeeStats.getUsersCount().toString()
        else
            return "0"
    }
}
