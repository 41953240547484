import React, { PureComponent, ReactNode } from 'react'
import { Box, Grid } from "@mui/material";
import { PercentageCard } from "../../Component/PercentageCard";
import { StatisticsCard } from "../../Component/Card";
import { i18n } from "../../../../../Configuration/I18n";
import { CartesianData } from "../../../../Domain/Entity/CartesianData";
import { EmployeeData } from "../../../../Domain/Entity/EmployeeData";
import { AreaChartWithLimit } from "../../Component/AreaChartWithLimit";
import { PeriodFilter } from "./PeriodFilter";


interface Props {
    employeeStats: EmployeeData | null
    usersStats: CartesianData[] | null;
    onFilter: (value: string) => void
}

interface State {
    filterValue: string
}

export class UsersStats extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            filterValue: 'all'
        }
    }

    render(): ReactNode {
        return (
            <Box>
                <Grid container spacing={3} mb={3}>
                    <Grid item xs={12} lg={4}>
                        <PercentageCard title={i18n.statistics.subscription_rate}
                                        numerator={this.props.employeeStats?.getUsersCount()}
                                        denominator={this.props.employeeStats?.getEmployeeCount()}
                        />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <StatisticsCard title={i18n.statistics.users}
                                        value={this.props.employeeStats ? this.props.employeeStats.getUsersCount().toString() : '0'}/>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <StatisticsCard title={i18n.statistics.employees}
                                        value={this.props.employeeStats ? this.props.employeeStats.getEmployeeCount().toString() : '0'}/>
                    </Grid>
                </Grid>

                <Grid container spacing={3} mb={3}>
                    <AreaChartWithLimit title={i18n.statistics.cumulative_of_new_users}
                                        stats={this.props.usersStats}
                                        limitLabel={i18n.statistics.employee_number}
                                        valueLabel={i18n.statistics.users_number}
                                        showLimit={this.state.filterValue === 'all'}
                                        limit={this.props.employeeStats ? this.props.employeeStats.getEmployeeCount() : 0}>

                        <PeriodFilter onFilter={value => {
                            this.setState({ filterValue: value })
                            this.props.onFilter(value)
                        }}/>
                    </AreaChartWithLimit>
                </Grid>
            </Box>
        )
    }

}