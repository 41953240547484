import React, { PureComponent, ReactNode } from "react";
import { Box, Grid } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { MultivariateData } from "../../../Domain/Entity/MultivariateData";
import { i18n } from "../../../../Configuration/I18n";
import { StatisticsGridTitle } from "./Title";
import { Theme } from "../../../../Common/Config/Theme";

interface Props {
    title: string;
    stats: MultivariateData[];
    dataType?: 'CURRENCY' | 'DEFAULT';
}

export class StatisticsMultiBarChart extends PureComponent<Props> {

    static defaultProps = {
        dataType: 'DEFAULT'
    };

    render(): ReactNode {
        return (
            <Grid item sm={12} md={6}>
                <Box className={'statistics-grid'}>
                    <StatisticsGridTitle title={this.props.title}
                                         info={this.props.title}/>

                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart width={500}
                                  height={300}
                                  style={{ fontFamily: 'sans-serif', fontSize: 11 }}
                                  data={this.props.stats}
                                  margin={{ top: 20, right: 30, bottom: 20, left: 30 }}>
                            <CartesianGrid strokeDasharray="3 3"/>

                            <XAxis dataKey="label"/>
                            <YAxis width={100} tickFormatter={(value: number) => this.format(value)}/>

                            <Tooltip formatter={(value: number) => this.format(value)}/>
                            <Legend/>

                            <Bar dataKey="valueB" fill={Theme.grayishBlue} name={i18n.statistics.goal}/>
                            <Bar dataKey="valueA" fill={Theme.darkGreen} name={i18n.statistics.ca}/>
                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            </Grid>
        )
    }

    format(value: number): string {
        let options: object;
        switch (this.props.dataType) {
            case'CURRENCY':
                options = { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 };
                break
            case "DEFAULT":
                options = { style: 'decimal', maximumFractionDigits: 0 };
                break
            default:
                options = { style: 'decimal', maximumFractionDigits: 0 };
                break
        }
        const numberFormat = new Intl.NumberFormat('fr-FR', options);

        return numberFormat.format(value).replace(/\s/g, '  ');
    }
}
