import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationDependenciesFactory } from "../../../../Authentication/Config/Dependencies.factory";
import { SecuredObservableAjaxHttpClient } from "../../../../Common/Adapters/Secondaries/Real/SecuredObservableAjax.httpClient";
import { AdventureService } from "../../../Domain/Gateway/Adventure.service";
import { Adventure } from "../../../Domain/Entity/Adventure";
import { AdventureMapper } from './Mapper/Adventure.mapper';
import { AdventureDTO } from "./DTO/Adventure.DTO";
import { MCQ } from "../../../Domain/Entity/MCQ";
import { MCQQuestion } from "../../../Domain/Entity/MCQQuestion";
import { MCQResponse } from "../../../Domain/Entity/MCQResponse";
import { ResponseMCQ } from "./DTO/MCQDto";

export class ApiAdventureService implements AdventureService {

    getAdventureList(): Observable<Adventure[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/adventure/all'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: AdventureDTO[] }>(url)
            .pipe(
                map((response: { data: AdventureDTO[] }) => AdventureMapper.mapDataToAdventureList(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    addAdventure(adventure: Adventure): Observable<string> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/adventure'
        const body = new FormData();
        body.append('label', adventure.name);
        body.append('details', adventure.details);
        body.append('start_date', adventure.startDate.toString());
        body.append('end_date', adventure.endDate.toString());
        body.append('reward', adventure.reward.toString());
        body.append('active', adventure.enabled ? 'true' : 'false');
        body.append('picture', adventure.picture);

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(response => response.response.data),
                catchError(err => throwError(err.status.toString())
                )
            )
    }

    addMCQ(mcq: MCQ): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/mcq'
        const body = new FormData();
        body.append('title', mcq.title);
        body.append('adventureId', mcq.adventureId);
        body.append('reward', mcq.reward.toString());
        if (mcq.questions[0].picture)
            body.append('picture', mcq.questions[0].picture);

        body.append('questions', JSON.stringify(this.mapQuestionList(mcq.questions)));

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }

    updateAdventure(adventure: Adventure): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/adventure/' + adventure.id + '/update'
        const body = new FormData();
        body.append('label', adventure.name);
        body.append('details', adventure.details);
        body.append('start_date', adventure.startDate.toString());
        body.append('end_date', adventure.endDate.toString());
        body.append('reward', adventure.reward.toString());
        body.append('active', adventure.enabled ? 'true' : 'false');
        body.append('picture', adventure.picture);
        body.append('uuid', adventure.id)

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.response.message)
                )
            )
    }

    updateMCQQuestion(question: MCQQuestion, mcqId: string): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/mcq/' + mcqId + '/question/' + question.id + '/update'
        const body = new FormData();
        body.append('text', question.question);
        body.append('mcqId', mcqId)
        body.append('questionId', question.id.toString())
        if (question.description)
            body.append('description', question.description);
        if (question.picture)
            body.append('picture', question.picture);

        body.append('responses', JSON.stringify(this.mapResponsesListToUpdate(question.responses)));

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.response.message))
            )
    }

    createMCQQuestion(question: MCQQuestion, mcqId: string): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/mcq/' + mcqId + '/question'
        const body = new FormData();
        body.append('text', question.question);
        body.append('mcqId', mcqId)
        if (question.description)
            body.append('description', question.description);
        if (question.picture)
            body.append('picture', question.picture);

        body.append('responses', JSON.stringify(this.mapResponsesListToCreate(question.responses)));

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.response.message)
                )
            )
    }

    deleteMCQQuestion(questionId: number, mcqId: string): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/back/mcq/' + mcqId + '/question/' + questionId + '/delete'
        const body = new FormData();
        body.append('mcqId', mcqId)
        body.append('questionId', questionId.toString())

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.response.message))
            )
    }

    getAdventureMCQ(adventureId: string): Observable<MCQ> {
        const url = process.env.REACT_APP_API_URL + '/v1/mcq/' + adventureId
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: ResponseMCQ }>(url)
            .pipe(
                map((response: { data: ResponseMCQ }) => AdventureMapper.mapDataToMCQ(response.data.mcq)),
                catchError(err => throwError(err)
                )
            )
    }

    mapQuestionList(questions: MCQQuestion[]) {
        return questions.map(item => {
            const responses = item.responses.map(response => ({
                text   : response.text,
                correct: response.isCorrect
            }))
            return {
                text       : item.question,
                description: item.description,
                responses
            }
        })
    }

    mapResponsesListToUpdate(responses: MCQResponse[]) {
        return responses.map(response => ({
            id     : response.id,
            text   : response.text,
            correct: response.isCorrect
        }))
    }

    mapResponsesListToCreate(responses: MCQResponse[]) {
        return responses.map(response => ({
            text   : response.text,
            correct: response.isCorrect
        }))
    }
}
