import { CartesianData } from "../Entity/CartesianData";
import { EmployeeData } from "../Entity/EmployeeData";

export class EmployeeDataBuilder {
    protected _employees: CartesianData[]
    protected _users: CartesianData[]

    withEmployees(employees: CartesianData[]): EmployeeDataBuilder {
        this._employees = employees
        return this
    }
    withUsers(users: CartesianData[]): EmployeeDataBuilder {
        this._users= users
        return this
    }

    build(): EmployeeData{
        return new EmployeeData(
            this._employees,
            this._users
        )
    }
}
