import { Adventure } from "../Entity/Adventure";

export class AdventureBuilder {

    protected _id: string
    protected _name: string
    protected _details: string
    protected _startDate: Date
    protected _endDate: Date
    protected _picture: File
    protected _pictureName: string
    protected _enabled: boolean
    protected _reward: number

    withId(id: string): AdventureBuilder {
        this._id = id
        return this
    }

    withName(name: string): AdventureBuilder {
        this._name = name
        return this
    }

    withDetails(details: string): AdventureBuilder {
        this._details = details
        return this
    }

    withStartDate(startDate: Date): AdventureBuilder {
        this._startDate = startDate
        return this
    }

    withEndDate(endDate: Date): AdventureBuilder {
        this._endDate = endDate
        return this
    }

    withPicture(picture: File): AdventureBuilder {
        this._picture = picture
        return this
    }
    withPictureName(name: string): AdventureBuilder {
        this._pictureName = name
        return this
    }

    withEnabled(enabled: boolean): AdventureBuilder {
        this._enabled = enabled
        return this
    }

    withReward(reward: number): AdventureBuilder {
        this._reward = reward
        return this
    }

    build(): Adventure {
        return new Adventure(
            this._id,
            this._name,
            this._details,
            this._startDate,
            this._endDate,
            this._picture,
            this._pictureName,
            this._enabled,
            this._reward
        )
    }
}
