import React, { PureComponent, ReactNode } from "react";
import { Box, Grid } from '@mui/material';
import { CartesianData } from "../../../Domain/Entity/CartesianData";
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { CartesianDataBuilder } from "../../../Domain/Builder/CartesianData.builder";
import { ApplicationContext } from "../../../../Configuration/Application.context";
import { StatisticsGridTitle } from "./Title";
import { Theme } from "../../../../Common/Config/Theme";

const moment = ApplicationContext.getInstance().momentJs()

interface Props {
    title: string;
    stats: CartesianData[] | null;
    legend: string;
    children: JSX.Element;
}

interface State {
    filter: string;
}

export class StatisticsAreaChart extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            filter: 'all'
        }
    }

    render(): ReactNode {
        return (
            <Grid container spacing={3} mb={3}>
                <Grid item xs={12}>
                    <Box className={'statistics-grid'}>
                        <StatisticsGridTitle title={this.props.title} info={this.props.title}>
                            {this.props.children}
                        </StatisticsGridTitle>

                        <ResponsiveContainer width="100%" height={300}>
                            <AreaChart width={500}
                                       height={300}
                                       style={{ fontFamily: 'sans-serif', fontSize: 12 }}
                                       data={this.props.stats ? this.getFormattedData(this.props.stats) : []}
                                       margin={{ top: 20, right: 40, bottom: 20, left: 0 }}>

                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="label"/>
                                <YAxis/>
                                <Tooltip/>

                                <Area type="monotone" dataKey="value" name={this.props.legend}
                                      stroke={Theme.grayishBlue}
                                      strokeWidth={2} fill={Theme.grayishBlue}/>
                            </AreaChart>
                        </ResponsiveContainer>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    getFormattedData(stats: CartesianData[]) {
        return stats.map(stat =>
            new CartesianDataBuilder()
                .withLabel(moment(stat.label, 'YYYY-MM-DD').format('DD MMM'))
                .withValue(stat.value)
                .build()
        )
    }
}
