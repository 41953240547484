export class Adventure {
    constructor(
        protected _id: string,
        protected _name: string,
        protected _details: string,
        protected _startDate: Date,
        protected _endDate: Date,
        protected _picture: File,
        protected _pictureName: string,
        protected _enabled: boolean,
        protected _reward: number
    ){}

    get id(): string {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get details(): string {
        return this._details;
    }

    get startDate(): Date {
        return this._startDate;
    }

    get endDate(): Date {
        return this._endDate;
    }

    get picture(): File {
        return this._picture;
    }

    get pictureName(): string {
        return this._pictureName
    }

    get enabled(): boolean {
        return this._enabled;
    }

    get reward(): number {
        return this._reward;
    }
}
