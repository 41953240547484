import React, { PureComponent, ReactNode } from 'react'
import { i18n } from "../../../../../Configuration/I18n";
import { Box, Grid } from "@mui/material";
import { PercentageCard } from "../../Component/PercentageCard";
import { StatisticsCard } from "../../Component/Card";
import { AdventureData } from "../../../../Domain/Entity/AdventureData";
import { CartesianData } from "../../../../Domain/Entity/CartesianData";

interface Props {
    adventureStats: AdventureData
}

export class AdventureProgressStats extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <Box>
                <Grid container spacing={3} mb={3}>
                    <Grid item xs={12} lg={4}>
                        <PercentageCard title={i18n.statistics.turnover_rate}
                                        numerator={this.props.adventureStats.totalTurnover}
                                        denominator={this.props.adventureStats.totalTurnoverGoal}
                        />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <StatisticsCard type={'CURRENCY'} title={i18n.statistics.turnover_value}
                                        value={this.props.adventureStats.totalTurnover.toString()}/>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <StatisticsCard type={'CURRENCY'} title={i18n.statistics.turnover_goal}
                                        value={this.props.adventureStats.totalTurnoverGoal.toString()}/>
                    </Grid>
                </Grid>
                <Grid container spacing={3} mb={3}>
                    <Grid item xs={12} lg={4}>
                        <PercentageCard title={i18n.statistics.activated_client_rate}
                                        numerator={this.props.adventureStats.totalActivatedClient}
                                        denominator={this.props.adventureStats.totalActivatedClientGoal}/>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <StatisticsCard title={i18n.statistics.activated_client_value}
                                        value={this.props.adventureStats.totalActivatedClient.toString()}/>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <StatisticsCard title={i18n.statistics.activated_client_goal}
                                        value={this.props.adventureStats.totalActivatedClientGoal.toString()}/>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    getRateActivatedClientStats(): CartesianData[] {
        const activatedClientGoal = this.props.adventureStats ? ((this.props.adventureStats.totalActivatedClient / this.props.adventureStats.totalActivatedClientGoal) * 100) : 0
        const activatedClient = this.props.adventureStats ? (((this.props.adventureStats.totalActivatedClientGoal - this.props.adventureStats.totalActivatedClient) / this.props.adventureStats.totalActivatedClientGoal) * 100) : 0
        return [new CartesianData('activatedClientGoal', activatedClientGoal),
            new CartesianData('activatedClient', activatedClient)
        ]

    }
}